<template>
  <div>
    <b-container>
      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            <span class="text-secondary">مایندولوژی</span> را بهتر بشناسید
          </h2>
        </b-col>

        <b-col cols="10" md="8" offset-md="2" offset="1" class="text-center">
          <p class="mx-auto text-center">
            تجربه درمان سریعتر و هدفمند تر  با پلتفرم جامع روانشناسی
          </p>
        </b-col>
        <b-col cols="10" md="8" offset-md="2" offset="1" class="text-center">
          <img
            src="@/assets/images/about-top.png"
            alt="about-top"
            class="w-100"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex align-items-center mt-8" v-if="!isMobile()">
        <b-col cols="12" md="7">
          <h2 class="my-4 text-primary font-weight-bold">
            چند خط <span class="text-secondary">درباره ما</span>
          </h2>

          <img src="@/assets/images/divider.png" alt="divider" class="my-1" />
          <p class="mt-4 text-justify w-75">
            ما جمعی از افراد متخصص در علوم کامپیوتری هستیم که
            بعد از 3 سال کار تحقیقاتی میتونیم با ترکیب تکنولوژی و
            علم روانشناسی، تجربه درمان سریعتر و هدفمندتری رو برای
            مراجعین و مشاورین رقم بزنیم و با کاهشهزینه به توسعه
            و افزایش جذب مراجع کلینیک های روانشناسی کمک کنیم.
          </p>
        </b-col>
        <b-col cols="12" md="5">
          <img
            src="@/assets/images/about-middle.png"
            alt="about-middle"
            class="my-4 w-100"
          />
        </b-col>
      </b-row>

      <b-row class="d-flex align-items-center mt-8" v-if="isMobile()">
        <b-col cols="12" class="text-center">
          <h2 class="my-4 text-primary font-weight-bold text-center">
            چند خط <span class="text-secondary">درباره ما</span>
          </h2>

          <img src="@/assets/images/divider.png" alt="divider" class="my-1" />
        </b-col>
        <b-col cols="12">
          <img
            src="@/assets/images/about-middle.png"
            alt="about-middle"
            class="my-4 w-100"
          />
        </b-col>

        <b-col cols="12">
          <p class="mt-4 text-justify px-4">
            ما جمعی از افراد متخصص در علوم کامپیوتری هستیم که
            بعد از 3 سال کار تحقیقاتی میتونیم با ترکیب تکنولوژی و
            علم روانشناسی، تجربه درمان سریعتر و هدفمندتری رو برای
            مراجعین و مشاورین رقم بزنیم و با کاهش هزینه به توسعه
            و افزایش جذب مراجع کلینیک های روانشناسی کمک کنیم.
          </p>
        </b-col>
      </b-row>
    </b-container>

    <!-- <b-container>
      <b-row>
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            قصه از کجا <span class="text-secondary">شروع</span> شد؟
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
        </b-col>
        <b-col cols="12" md="12">
          <v-stepper alt-labels class="border-0 shadow-none" v-model="e1">
            <v-stepper-items>
              <v-stepper-content
                v-for="n in steps"
                :key="`${n.number}-step`"
                :step="n.number"
              >
                <div
                  class="text-center d-flex justify-content-center align-items-center"
                >
                  <v-btn icon fab @click="prevStep(n.number)" class="me-3">
                    <img src="@/assets/images/next.png" alt="prev" class="" />
                  </v-btn>

                  <div class="flex-grow-1 text-center">
                    <img :src="getImage(n.pic)" class="curve-25 w-75" />
                    <p class="text-center mt-4 d-none">
                      لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ
                      و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه
                      روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای
                      شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف
                      بهبود ابزارهای کاربردی می باشد
                    </p>
                  </div>
                  <v-btn icon fab @click="nextStep(n.number)" class="ms-3">
                    <img src="@/assets/images/prev.png" alt="next" class="" />
                  </v-btn>
                </div>
              </v-stepper-content>
            </v-stepper-items>

            <v-stepper-header class="border-0 shadow-none">
              <template v-for="n in steps">
                <v-divider v-if="n.number == 1" :key="n.number"></v-divider>

                <v-stepper-step
                  :key="`${n.number}-step`"
                  :step="n.number"
                  editable
                  color="secondary"
                >
                  {{ n.title }}
                </v-stepper-step>

                <v-divider :key="n.number"></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>
        </b-col>
      </b-row>
    </b-container> -->

<!--    <b-container>-->
<!--      <b-row>-->
<!--        <b-col cols="12" md="12" class="text-center">-->
<!--          <h2 class="my-4 text-center text-primary font-weight-bold">-->
<!--            <span class="text-secondary">تیم</span> مایندولوژی-->
<!--          </h2>-->
<!--          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />-->
<!--        </b-col>-->

<!--        <b-col cols="12" md="12">-->
<!--          <v-container fluid>-->
<!--                <b-row align-h="center">-->
<!--                  <b-col v-for="i in persons" :key="i" cols="12" md="3" >-->
<!--                    <v-img-->
<!--                      :src="getImageTeam(i.pic)"-->
<!--                      :lazy-src="getImageTeam(i.thumb)"-->
<!--                      class="curve-25 mx-2"-->
<!--                    ></v-img>-->
<!--                    <span class="d-block text-center mt-2">{{i.name}}</span>-->
<!--                    <span class="text-muted text-small d-block text-center">{{i.type}}</span>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--              </v-container>-->
<!--          &lt;!&ndash; <v-tabs color="deep-purple accent-4" centered>-->
<!--            <v-tab>همه</v-tab>-->
<!--            <v-tab>تیم فنی</v-tab>-->
<!--            <v-tab>تیم مشاوره</v-tab>-->

<!--            <v-tab-item v-for="n in 3" :key="n">-->
<!--              <v-container fluid>-->
<!--                <v-row>-->
<!--                  <v-col v-for="i in persons" :key="i" cols="12" md="3">-->
<!--                    <v-img-->
<!--                      :src="getImageTeam(i.pic)"-->
<!--                      :lazy-src="getImageTeam(i.thumb)"-->
<!--                      class="curve-25 mx-2"-->
<!--                    ></v-img>-->
<!--                    <span class="d-block text-center mt-2">{{i.name}}</span>-->
<!--                    <span class="text-muted text-small d-block text-center">{{i.type}}</span>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-container>-->
<!--            </v-tab-item>-->
<!--          </v-tabs> &ndash;&gt;-->
<!--        </b-col>-->
<!--      </b-row>-->
<!--    </b-container>-->

    <b-container>
      <b-row class="d-flex align-items-center">
        <b-col cols="12" md="12" class="text-center">
          <h2 class="my-4 text-center text-primary font-weight-bold">
            با ما در <span class="text-secondary">ارتباط</span> باشید
          </h2>
          <img src="@/assets/images/divider.png" alt="divider" class="my-4" />
        </b-col>

        <div class="d-lg-flex d-md-flex justify-content-center">
          <b-col cols="12" md="6" lg="4" class="my-4">
            <v-card class="mx-auto curve-30" outlined>
              <v-list-item three-line>
                <v-list-item-content class="ms-3">
                  <v-list-item-title class="mb-1 h5">
                    پست الکترونیکی
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    hi@mindology.io
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar size="80" color="#F0F7FF" class="position-absolute p-0 m-0" style="top:-40px; left:40px">
                  <img src="@/assets/images/email.png" alt="divider" class="w-auto h-auto rounded-0"/>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </b-col>

          <b-col cols="12" md="6" lg="4" class="my-4">
            <v-card class="mx-auto curve-30" outlined>
              <v-list-item three-line>
                <v-list-item-content class="ms-3">
                  <v-list-item-title class="mb-1 h5">
                    تماس تلفنی
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    09122945346
                  </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar size="80" color="#FFF0E9" class="position-absolute p-0 m-0" style="top:-40px; left:40px">
                  <img src="@/assets/images/phone.png" alt="divider" class="w-auto h-auto rounded-0"/>
                </v-list-item-avatar>
              </v-list-item>
            </v-card>
          </b-col>
        </div>

<!--        <b-col cols="12" md="4" class="my-4">-->
<!--          <v-card class="mx-auto curve-30" outlined>-->
<!--            <v-list-item three-line>-->
<!--              <v-list-item-content class="ms-3">-->
<!--                <v-list-item-title class="mb-1 h5">-->
<!--                  ربات پشتیبانی-->
<!--                </v-list-item-title>-->
<!--                <v-list-item-subtitle>-->
<!--                  از <b-link class="text-secondary">اینجا</b-link> استفاده کنید-->
<!--                </v-list-item-subtitle>-->
<!--              </v-list-item-content>-->

<!--              <v-list-item-avatar size="80" color="#ECFFF4" class="position-absolute p-0 m-0" style="top:-40px; left:40px">-->
<!--                <img src="@/assets/images/bot.png" alt="divider" class="w-auto h-auto rounded-0"/>-->
<!--              </v-list-item-avatar>-->
<!--            </v-list-item>-->
<!--          </v-card>-->
<!--        </b-col>-->
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      e1: 1,
      steps: [
        { number: 1, title: "اسفند 1398", pic: "stepper-1.png" },
        { number: 2, title: "مرداد 1400", pic: "stepper-2.jpg" },
        { number: 3, title: "مهر 1400", pic: "stepper-3.jpg" },
        { number: 4, title: "این راه ادامه دارد", pic: "stepper-4.jpg" },
      ],
      persons: [
          { id: 1, name: "محسن خوشکام" ,type: "بنیان‌گذار", pic: "full/6.jpg", thumb: "thumb/6.jpg"},
          { id: 2, name: "کبری قهرمانی" ,type: "هم بنیانگذار", pic: "full/2.jpg", thumb: "thumb/2.jpg"},
          { id: 3, name: "نادر میری" ,type: "هم بنیانگذار", pic: "full/1.jpg", thumb: "thumb/1.jpg"},
          { id: 4, name: "یاسر نیکنام" ,type: "توسعه دهنده اندروید", pic: "full/3.jpg", thumb: "full/3.jpg"},
          { id: 5, name: "احسان زینعلی‌نیا" ,type: "توسعه دهنده ios", pic: "full/5.jpg", thumb: "thumb/5.jpg"},
          { id: 6, name: "رضا روشنی" ,type: "توسعه دهنده", pic: "full/7.jpg", thumb: "full/7.jpg"},
          // { id: 7, name: "حجت عابدی" ,type: "توسعه دهنده", pic: "user.png", thumb: "user.png"},
          { id: 8, name: "کوشا صباغی" ,type: "تولید محتوا", pic: "full/4.jpg", thumb: "thumb/4.jpg"},
          { id: 9, name: "فاطمه جوکار" ,type: "روانشناس", pic: "user.png", thumb: "user.png"},
          // { id: 10, name: "محمد امین قاسمی" ,type: "طراح رابط کاربری", pic: "user.png", thumb: "user.png"},
      ]
    };
  },
  methods: {
    prevStep(n) {
      if (n == 1) {
        this.e1 = this.steps.length;
      } else {
        this.e1 = n - 1;
      }
    },
    nextStep(n) {
      if (n == this.steps.length) {
        this.e1 = 1;
      } else {
        this.e1 = n + 1;
      }
    },
    getImage(src) {
      return require(`@/assets/images/${src}`);
    },
    getImageTeam(src) {
      return require(`@/assets/images/team/${src}`);
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
